
.price-container{
    border:3px solid whitesmoke;
    padding:1rem;
}

.price-container ul{
    list-style-type: none;
    padding:0;

}

.card-grid{
    display: grid;
    line-height: 1.6;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    gap:10px;
}
.card-grid-2{
    display: grid;
    line-height: 1.6;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap:10px;
}


@media  (max-width: 1250px) {

    .card-grid-2{
        grid-template-columns: repeat(1, 1fr);
    }
    
}

.price-card
{
    padding:2rem 2rem 2rem 2rem;
    background-color: #ffffff;
    color:#000000;
    border-radius: 1rem;
    background-color:whitesmoke;
    
}

.price-card-header{
    display: flex;
    justify-content: space-between;
}



.price-card ul{
    list-style-type:none;
    margin: 0;
    padding:0;
}