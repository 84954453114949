.brand
{
    text-align: center;
}


.grid
{
    display: flex;
    justify-content:center;
    flex-direction: row;
}


@media(max-width:768px) {
    .grid
    {
        display: flex;
        justify-content: center;
        align-items: center;;
        flex-direction: column;
    }
    
}