.gold{
    color: gold;
}

.review-item{
    width: 400px;
    margin-top:20px;
}

.review-date{
    color: #999;
}

.open-quote{
    margin-right: 5px;
    font-size: 25px;
}
.close-quote{
    margin-left: 5px;;
}