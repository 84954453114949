* {
    box-sizing: border-box;
    padding:0px;
    margin:0px;
    scroll-behavior: smooth;
    font-family: 'Noto Sans', sans-serif;
}

html, body {
  height: 100%;
  margin: 0px;
}

a{
  text-decoration: none;
  color:black;
}

a:hover{
  color:black;
}

h4{ 
  font-weight: bold;
}

.imgborder
{
    padding: 2px;
    background-color: white;
    border-radius: 20px;
    margin:5px;
    box-shadow: 5px 10px 8px #888888;
}

.root{
  position:relative;
}