.article-image-left {
    display: flex;
    gap:5px;
}

.article-image-left>div:last-child {
    margin: auto;
    width: 75%;
}

.infoBar {
    display: grid;
    justify-content: space-around;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2px;
    text-align: center;
}

.infoBar>div {
    background-color: rgb(255, 246, 192);
    padding: 10px;
    border-radius: 5px;
}

.lesion{
    background-color: whitesmoke;
    border-radius: 5px;
    padding:5px;
}
.lesion2{
    background-color: whitesmoke;
    border-radius: 5px;
    padding:5px;
}

@media (min-width: 800px) {

    .lesion{
        
        display: grid;
        grid-template-columns: 250px  .2fr 1fr;
        align-items: center;
        gap:5px;
    }
    .lesion2{
        display: grid;
        grid-template-columns: 250px  1fr;
        align-items: center;
        gap:5px;
    }
}

@media (max-width: 800px) {

    .lesion:first-child{
        margin: 10px;
        
    }
   
    .infoBar {
        display: grid;
        justify-content: space-between;
        grid-template-columns: 1fr;
        gap: 2px;
    }

    .infoBar>div {
        background-color: rgb(255, 246, 192);
        padding: 10px;
        border-radius: 5px;
        
    }
    .infoBar>div:last-child{
        
    }


}