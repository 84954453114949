

.carousel-container
{
    display: flex;
    justify-content: center;
    
}

.carousel-container {
    justify-content: center;
}

.image-header{
    align-content: center;
    text-align: center;
}
