
.banner-container{
    background-color: #f1f1f1;
    box-shadow: 0px 5px 5px #ccc;
    padding:10px;
    
}

.banner-content{
    display:flex;
    justify-content: center;
}

.banner-item{
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    justify-content: center;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding : 5px;
    

}
.banner-item > i {
    text-align: center;
    grid-row-start: 1;
    grid-row-end: 3;
}
.banner-item > h4 {
    font-weight: 600;
    font-size: 18px;
}
.banner-item > p{
    text-align: center;
    font-weight: 300;
}

.banner-space{
    width: 100%;
    height: 20px;
}


@media(max-width:768px) {
    
    .banner-container{
        padding:10px;
        background-color: white;
        box-shadow: 0px 5px 5px white;
    }

    .banner-content{
        display: flex;
        flex-direction: column;;
        background-color: #f1f1f1;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0px 5px 10px 0px #ccc;
    }

    .banner-item{
        display: grid;
        grid-template-columns: auto ;
        justify-content: center;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;
        padding : 5px;
    }

    .banner-item > i {
        text-align: center;
        
        
    }
    .banner-item > h4 {
        font-weight: 600;
        font-size: 18px;
    }
    .banner-item > p{
        text-align: center;
        font-weight: 300;
    }

  
}

