.socialMedia-box{
    position:fixed;
    top:30%;
    height:110px;
    right:0px;
    width:50px;
    padding:5px;
    color:white;
    background-color: #212529;
    border-radius: 10px 10px 10px 10px;
}

.socialMedia-box .pip{
    text-align: center;
    display:inline-block;

}

@media only screen and (max-width: 600px) {
    .socialMedia-box{
        position:sticky;
        bottom:0px;
        padding:-2px;
        top:0px;
        height:80px;
        width:100%;
        background-color: #212529;
        color:white;
        border-radius: 10px 10px 0 0;
        
    }
    .socialMedia-box .pip{
        text-align: center;
        display:inline-block;
        
    }
  }