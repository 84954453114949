.review-contianer{
    display: grid;
    justify-content: center;
    align-content: center;
  
    gap: 4px;
    grid-auto-flow: column;
}

.review-header{
    display: grid;
    justify-content: center;
    align-content: center;

}

.review-section{

}