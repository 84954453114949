.image-gallery{
    display: flex;
    flex-wrap:wrap;
    justify-content:center;
    gap:10px;
    list-style: none;

}
.image-gallery > li{
    flex-grow:0;
}
.image-gallery > li img{
    object-fit: cover;
    max-width: 30vh;
    vertical-align: middle;
    
}
