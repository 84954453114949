p{
    font-size: 18px;
    text-align: left;
    color:black;
    
}

.max {
    min-height: 800px;
}

.card{
    width: 400px;
    margin:10px;
    
}

.ba_container
{
    display: grid;

}