
.standardText{
    font-weight:400;
    font-size: 18px;
}

.banner{
    box-shadow: 0px 10px 5px #ccc;
    text-align: center;
    border-radius: 5px;
    padding:20px;
    min-height: 50px;
}

.bannerBlockStart{
    background-color: #ccc;
    padding:5px;
    min-height: 50px;
}
.bannerBlockEnd{
    min-height: 50px;
}
.bannerBlock{
    min-height: 50px;
}
.box{
    display: flex;
    align-items: center;
    justify-content: center;
}
.padded{
    margin:5px;
}
.backColor{
    background-color: white;
}

.no-bullet
{
    list-style-type:none;
}
.centred
{
    text-align: center;
}



.textBox
{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #888888;  
    box-shadow: 0px 0px 20px #888888;
    background-color: #ccc;
}

.justified
{
    text-align: justify;
}


.ba-image{
    padding: 2px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 5px 10px 8px #888888;
    width: 75%;
}



.flex-item{
    flex-basis:   5rem;
}
.ba-container{
    display: grid;
    justify-content:space-between;
    align-content:space-aroundy;
  
    gap: 4px;
    grid-auto-flow: column;
}