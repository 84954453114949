.pip
{
    background-color: #212529;
    border:0px outset black;
    margin: 2px;
    padding:2px;
}

.pip-icon{
    width: 100%;
    margin-top:5px;
    margin-bottom:5px;
}

@media only screen and (max-width: 600px) {
    .pip{
        padding:5px;
        color:white;
        width: 50px;
        height:50px;
        float:left;
    }
    .pip-icon{
        width: 30px;
        margin: 2px;
    }
  }