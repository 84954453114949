.skin-types-container{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    border: 2px solid #aaa;
    border-radius: 10px;
}
.skin-type-item{
    
    
    padding: 5px;
    font-size: 18px;
    text-align: center;
}


section#microneedling > div{
    margin-top: 25px;
}

.header{
    width: 100%;
    height: 20vh;
    padding:0px !important;
    position: relative;
    background-color: #f1f1f1;
    border: 0px; 
    
}

.header-container
{
    position: absolute;
    top:50px;   
    width: 100%;
    
}

.row-spacer{
    width: 100%;
    height:20px;
}
.header-content{
    justify-content: center;
    display: flex;
    position: relative;
    grid-template-columns:   768px    ;
    grid-template-rows: 2;
    
}

.central{
    color:black;
}
.central> h1 {
    color:black;
}
.central> p {
    color:black;
}



@media(max-width:768px) {

 

    .header-content{
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    .central > h1{
        align-content: center;
    }
    .header-container
    {
        position: absolute;
    
    }
}
