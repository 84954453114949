.page-section {
    background-color: #fff;
    padding-bottom: 50px;

}

.main {
    background-size: cover;
    background-image: url('../../assets/back_bubblelight.jpg');
    min-height: 800px;
    color: white;
}

p {
    margin: 0px;
    padding: 5px;
    text-align: justify;
    font-size: 22px;
}

h1 {
    color: black;
}

.spacer-vert {
    margin-bottom: 60px;
}

.imgborder {
    padding: 2px;
    background-color: white;
    border-radius: 20px;
    margin: 5px;
    box-shadow: 5px 10px 8px #888888;
    width: 100%;
}

.a-intext {
    color: blue;
    text-decoration: underline;
}

.row-space {
    width: 100%;
    height: 40px;

}

.img-logo{
    max-width: 100%;
    max-height: 100%;
    padding:0px;
    margin: 0px;;
    
}
.btn-submit {
    width:80%;
    background-color:darksalmon;
    padding:0px;
    border-radius: 10px;
    min-height: 100px;
    font-size:1.5rem;
}
.btn-submit:hover {
    width:80%;
    background-color: gold;
    padding:0px;
    border-radius: 10px;
    min-height: 100px;
    
}
.calltoAction{
    
    margin: 0px;
    padding: 5px;
    font-size: 1.2rem;
    text-align:left;
    
}

.div-contacts{
    justify-items: start;
    display:grid;
    grid-template-columns: .5fr 1fr;
    gap:5px;
    
}