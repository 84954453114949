header {
    display:flex;
    justify-content: center;
    padding:4rem;
    position: relative;
    width: 100%;
    background-color: #f1f1f1;
    
}
.shadow{
    margin-bottom: 3rem;
}