@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;600;700;800&family=Manrope&family=Rubik:ital,wght@0,300;0,700;1,400&display=swap');

:root {
  --font-family: 'Inter', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg:#ffffff;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
}

.spacer-25{
  height: 25rem;
}
.spacer-5{
  height:5rem;
}
.spacer-2{
  height:2rem;
}
