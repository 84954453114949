.grid-container{
  display: grid;
  gap: 50px 10px;
  grid-template-columns: auto auto auto auto auto auto;
  background-color: white;
  
  padding: 10px;
}

.grid-item{
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
}


.grid-item-selected{
  background-color:blue;
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
}


.section{
  border:1px solid black;
  padding:5px;
  margin-bottom: 10px;
}

.table {
  background-color: #ddd;
  border :1px solid black;
}