
.navigation-back{
    background-color: #212529 ;
    color:white;

    .navbar{
        display:flex;
        justify-content:space-between;
        align-items: center;
    }
    
    .nav-Link{
        padding:0px;
        color:white;
        
    }
    
    .nav-text
    {
        color:white;
        padding:20px
    }
    .nav-text:hover{
        color:#ccc;
    }
    
    .navbar-links{
        flex:1;
        display:flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        color: black;
        font-weight: 900;
        
    }
/* 
    .navbar-toggler-icon{
        color:white;
    }
    */
    .navbar-nav{

        .nav-link{
            height: 100%;
            justify-content: center;
            padding:20px;
        }
        .show>.nav-link{
            color:white;
            
        }
        .nav-link:hover{
            color:white;
        }
        .nav-link{
            color:white;
            
        }
        .nav-link:active{
            color:white;
        }
        .nav-link:focus{
            color:white;
        }
        .dropdown-item{
            display:grid;
            grid-template-columns: 1fr;
            background: #212529;
            gap:5px;
            border-radius: 5px;
            
        }

        .nav-text-dropdown{
            color:white;
            padding:5px;

        }
        .nav-text-dropdown:hover{
            color:#ccc;
        }
        .show{
            background: #212529;
        }
    }
   

}







